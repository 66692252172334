import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'; // Import icons

Modal.setAppElement('#root'); // Set the root element for accessibility

const MessageModal = ({ isOpen, message, onClose, modalType }) => {
  useEffect(() => {
    if (isOpen) {
      const timeout = setTimeout(() => {
        onClose(); // Close the modal after 3 seconds (adjust timeout as needed)
      }, 3000); // Timeout duration in milliseconds

      return () => clearTimeout(timeout); // Cleanup the timeout on unmount or change
    }
  }, [isOpen, onClose]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal"
      overlayClassName="modal-overlay"
      contentLabel="Message Modal"
    >
      <div className="modal-content flex items-center">
        {modalType === 'success' ? (
          <FaTimesCircle className="text-red-500 mr-2" size={24} />
        ) : (
          <FaCheckCircle className="text-green-500 mr-2 animate-pulse" size={42} />
        )}
        <h2 className='text-base lg:text-lg'>{message}</h2>
      </div>
    </Modal>
  );
};

export default MessageModal;
