import Footer from './../Footer';
import Navbar from './../Navbar';
import Hero from './Hero';

function Home() {
    return (
      <div className="Home bg-gray-50">
        <Navbar/>
        <Hero/>
        <div className='block md:hidden' > 
        <Footer/>
        </div>
      </div>
    );
  }
  
  export default Home;
  