import Footer from './../Footer';
import Navbar from './../Navbar';
import Product from './Product';


function Services() {
    return (
      <div className="Home bg-gray-50">
        <Navbar/>
        <Product/>
        <Footer/>
      </div>
    );
  }
  
  export default Services;
  