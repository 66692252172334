import React from "react";
import logo from "./media/logo1.png";
const LoadingScreen = () => {
  return (
    <>
      <div className="absolute inset-0 z-10 bg-black  flex items-center justify-center  ">
        <div className=" animate-pulse">
          <img className=" h-32 w-auto " src={logo} alt="logo"></img>
        </div>
      </div>
    </>
  );
};

export default LoadingScreen;
