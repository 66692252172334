import { useState, } from "react";

function Footer() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  return (
    <div id="Footer">
     <footer className=" bottom-0 w-full bg-black">
        <div className="w-full max-w-screen-xl mx-auto p-4 md:py-0.5">
          <hr className=" border-white sm:mx-auto" />
          <span className="block text-base text-white text-center my-6">
            © {currentYear} XakiMetal™. All Rights Reserved.
          </span>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
