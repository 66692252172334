import React, { useEffect, useState } from "react";
import bgImage from "./../media/cnc.jpg"; // Replace with the path to your background image
import AOS from "aos";
import "aos/dist/aos.css";

const Hero = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  return (
    <div id="hero" className=" pt-10 md:pt-12 bg-black ">
      <div className="bg-black text-white">
        <div
          data-aos="fade-right"
          data-aos-duration="2000"
          className="lg:mr-40 lg:ml-40 flex flex-col md:flex-row items-center md:items-start md:justify-end md:space-x-8 p-8">
          <div className="md:w-1/2 md:order-2 mt-20 md:my-8 ">
            <h1 className="text-xl md:text-xl ">
              At our workshop we are focused on offering solutions to our clients
              for their customized sheet metal and profile parts demands. We
              handle projects from start to finish, ensuring each step meets your
              standards.
            </h1>
          </div>
        </div>
        <div className="bg-black md:bg-white md:grid md:grid-cols-2 ">
        <div className="flex justify-center items-center  w-full max-w-screen-xl mx-auto p-4 order-1 md:order-none ">
          <span className="hidden md:block text-base text-black text-center my-6">
            © {currentYear} XakiMetal™. All Rights Reserved.
          </span>
        </div>
        <img
          src={bgImage}
          alt="Team"
          className="  py-10 md:pb-10 pb-32 md:max-w-lg  object-cover filter grayscale hover:grayscale-0 transition-all duration-500"
        />  
        </div>
      </div>
   
    </div>
  );
};

export default Hero;
