import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import productImage from "./../media/cnc.webp"; // Replace with the path to your product image
import productImage3 from "./../media/person5.webp"; // Replace with the path to your product image
import productImage7 from "./../media/person7.webp"; // Replace with the path to your product image
import productImage14 from "./../media/person4.webp"; // Replace with the path to your product image



const Product = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  
  
  return (
    <div id="services" className="pt-10 md:pt-20 bg-black  ">
      <div className="bg-black text-white">
        <div
         
         className="lg:ml-40 lg:mr-40 flex flex-col md:flex-row items-start md:items-center md:justify-end md:space-x-8 p-8 my-10 md:my-20"
         >
          <div  data-aos="fade-right"
          data-aos-duration="2000" className="md:w-1/2">
            <h1 className="text-3xl md:text-4xl ">Services</h1>
          </div>
          <div data-aos="fade-left"
          data-aos-duration="2000" className="md:w-1/2 ">
            <p className="text-xl md:text-2xl">
            We handle the projects with dedication starting from the 3D CAD model, and combining
different metal fabrication techniques to meet the demands.
            </p>
          </div>
        </div>
        <div className="md:grid grid-cols-2 bg-white ">
  <div className="col-span-1 flex flex-col md:flex-row items-start md:items-center p-4 order-1 md:order-none">
    <p className="text-xl md:text-2xl text-center text-black">CNC Fiber laser cutting of both sheet metal and profile metal. For your custom made parts
    </p>
  </div>
  <div className="col-span-1 order-2 md:order-none">
    <img
      src={productImage}
      alt="Team"
      className="w-full object-cover "
    />
  </div>
</div>

<div className="md:grid grid-cols-2 bg-black ">
  <div className="col-span-1 flex flex-col md:flex-row items-start md:items-center p-4 order-1 md:order-2">
    <p className="text-xl md:text-2xl text-center text-white">
    Detailed inspection and support of the 3D CAD models provided
    </p>
  </div>
  <div className="col-span-1 order-2 md:order-none">
    <img
      src={productImage3}
      alt="Team"
      className="w-full object-cover  "
    />
  </div>
</div>


<div className="md:grid grid-cols-2 bg-white ">
  <div className="col-span-1 flex flex-col md:flex-row items-start md:items-center p-4 order-1 md:order-none">
    <p className="text-xl md:text-2xl text-center text-black">Press brake bending of various sheet metal parts.</p>
  </div>
  <div className="col-span-1 order-2 md:order-none">
    <img
      src={productImage14}
      alt="Team"
      className="w-full object-cover  "
    />
  </div>
</div>

<div className="md:grid grid-cols-2 bg-black ">
  <div className="col-span-1 flex flex-col md:flex-row items-start md:items-center p-4 order-1 md:order-2">
    <p className="text-xl md:text-2xl text-center text-white">
    Professional MIG-MAG welding and finishing</p>  
  </div>
  <div className="col-span-1 order-2 md:order-none">
    <img
      src={productImage7}
      alt="Team"
      className="w-full object-cover  "
    />
  </div>
</div>

      </div>
    </div>
  );
};

export default Product;
