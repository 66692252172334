import React, { useEffect } from "react";
import bgImage from "./../media/team.webp"; // Replace with the path to your background image
import machine from "./../media/machine.png"; // Replace with the path to your background image
// import bending from "./../media/bending.png"; // Replace with the path to your background image
// import laser from "./../media/laser.png"; // Replace with the path to your background image
// import pipe from "./../media/pipe.png"; // Replace with the path to your background image

import AOS from "aos";
import "aos/dist/aos.css";

const AboutUS = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div id="about" className="pt-10 md:pt-20 bg-black ">
      <div className="bg-black text-white">
        <div
          data-aos="fade-right"
          data-aos-duration="2000"
          className="lg:ml-40 lg:mr-40 flex flex-col md:flex-row items-start md:items-center md:justify-end md:space-x-8 p-8 my-10 md:my-20"
        >
          <div className="md:w-1/2">
            <h1 className="text-3xl md:text-4xl  my-8 md:my-0">About us</h1>
          </div>
          <div className="md:w-1/2 ">
            <p className="text-xl md:text-2xl">
              At our workshop, we combine various techniques and methods to fabricate metal parts,
              ensuring we meet our clients' demands and standards.
            </p>
          </div>
        </div>
        </div>

        <div className=" w-full h-full ">
        <img
          src={bgImage}
          alt="Team"
          className="w-full object-cover "
        />
        </div>
        {/* <p className="bg-white text-black text-xl md:text-2xl text-center px-4 md:px-36 py-10 md:py-40">
          Founded in 2001, our workshop brings together the extensive experience we've accumulated
          over the years to this very day. Our distinction lies in our emphasis on collaboration,
          responsibility, and extensive experience, setting us apart from the rest.
        </p> */}
        {/* <div className="bg-white ">
          <div className="md:grid grid-cols-3">
            <div>
            <img
          src={laser}
          alt="Team"
          className="w-full md:h-128 object-cover"
        />
            </div>
            <div>   <img
          src={pipe}
          alt="Team"
          className="w-full md:h-128 object-cover"
        /></div>
            <div>   <img
          src={bending}
          alt="Team"
          className="w-full md:h-128 object-cover"
        /></div>
          </div>
        </div> */}
         <div className=" w-full h-full bg-white ">
        <img
          src={machine}
          alt="machine"
          className="w-full object-cover scale-125 md:scale-90 "
        />
        </div>
    </div>
  );
};

export default AboutUS;
