import Footer from './../Footer';
import Navbar from './../Navbar';
import ContactUS from './ContactUS';


function Contact() {
    return (
      <div className="Home bg-gray-50">
        <Navbar/>
        <ContactUS/>
        <Footer/>
      </div>
    );
  }
  
  export default Contact;
  