import Footer from './../Footer';
import Navbar from './../Navbar';
import AboutUS from './AboutUS';


function About() {
    return (
      <div className="Home bg-gray-50">
        <Navbar/>
        <AboutUS/>
        <Footer/>
      </div>
    );
  }
  
  export default About;
  