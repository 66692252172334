import { useState, useEffect, useRef } from "react";
import logo from "./media/logo.png";
import { Twirl as Hamburger } from "hamburger-react";
import "./index.css";
function Navbar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const navRef = useRef(null);

  useEffect(() => {
    setCurrentPath(window.location.pathname);

    function handleResize() {
      if (window.innerWidth > 860) {
        setIsNavExpanded(false);
      }
    }

    function handleClickOutside(event) {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsNavExpanded(false);
      }
    }

    window.addEventListener("resize", handleResize);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLinkClick = (path) => {
    setCurrentPath(path);
    setIsNavExpanded(false);
  };

  return (
    <div className="Nav fixed w-full z-20 ">
      <nav className="bg-black">
        <div ref={navRef} className="max-w-screen-xl flex flex-wrap items-center justify-between  lg:mx-32   p-4 md:p-2">
          <a href="/" className="flex items-start -ml-4 md:ml-0 space-x-0 rtl:space-x-reverse">
            <img src={logo} className="h-16 md:h-20" alt="Logo" />
          </a>
          <button
            type="button"
            className="inline-flex items-center mx-2 w-10 h-10 justify-center text-md  rounded-lg md:hidden "
            aria-controls="navbar-default"
            aria-expanded={isNavExpanded}
            onClick={() => setIsNavExpanded(!isNavExpanded)}
          >
            <span className="sr-only">Open main menu</span>
            <Hamburger
              label="Show menu"
              easing="ease-in"
              toggled={isNavExpanded}
              toggle={setIsNavExpanded}
              size={30}
              distance="md"
              color="white"
              duration={0.5}
            />
          </button>
          <div className={`w-full md:block md:w-auto transition-all duration-1000 ease-in-out overflow-hidden ${isNavExpanded ? "max-h-screen opacity-100" : "max-h-0 md:max-h-full opacity-0 md:opacity-100"}`} id="navbar-default">
          <ul className="text-2xl  flex flex-col p-2 md:p-0 mt-4 border border-white bg-black md:flex-row md:space-x-32 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-transparent">
  <li>
    <a
      href="/services"
      onClick={() => handleLinkClick("/services")}
      className={`block py-2 px-3 rounded md:p-0 ${
        currentPath === "/services" ? "text-white active-link" : "text-white hover-link"
      }`}
      aria-current={currentPath === "/services" ? "page" : undefined}
    >
      Services
    </a>
  </li>
  <li>
    <a
      href="/about"
      onClick={() => handleLinkClick("/about")}
      className={`block py-2 px-3 rounded md:p-0 ${
        currentPath === "/about" ? "text-white active-link" : "text-white hover-link"
      }`}
      aria-current={currentPath === "/about" ? "page" : undefined}
    >
      About
    </a>
  </li>
  <li>
    <a
      href="/contact"
      onClick={() => handleLinkClick("/contact")}
      className={`block py-2 px-3 rounded md:p-0 ${
        currentPath === "/contact" ? "text-white active-link" : "text-white hover-link"
      }`}
      aria-current={currentPath === "/contact" ? "page" : undefined}
    >
      Contact
    </a>
  </li>
</ul>

          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
