import React, { useEffect, useState } from "react";
import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaLinkedin } from 'react-icons/fa';
import emailjs from '@emailjs/browser';
import AOS from "aos";
import "aos/dist/aos.css";
import MessageModal from "./Modal";
const ContactUS = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalType, setModalType] = useState('success'); // success or error
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Replace with your EmailJS service ID, template ID, and user ID
    const serviceID = 'service_2zzixtd';
    const templateID = 'template_83d347n';
    const userID = 'bL7fpE-oLdNLCef1Z';

    emailjs.send(serviceID, templateID, {
      to_name: 'XakiMetal Team', // Replace with dynamic value
      from_name: formData.name,
      from_email: formData.email,
      from_number: formData.phone,
      message: formData.message
    }, userID)
    .then((result) => {
      console.log(result.text);
      setModalMessage("Message Sent Successfully!");
      setModalType('success');
      setIsModalOpen(true);
      setFormData({
        name: '',
        email: '',
        phone:'',
        message: ''
      });
    }, (error) => {
      console.log(error.text);
      setModalMessage("An error occurred, please try again.");
      setModalType('error');
      setIsModalOpen(true);
    });
};

const closeModal = () => {
  setIsModalOpen(false);
};
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div id="services" className="pt-10 md:pt-20 bg-black">
      <div className="bg-black text-white lg:pr-40 lg:pl-40">
        <div className="flex flex-col md:flex-row items-start md:items-center md:justify-end md:space-x-8 p-8 my-10 md:my-20">
          <div data-aos="fade-right" data-aos-duration="2000" className="md:w-1/2">
            <h1 className="text-3xl md:text-4xl my-8 md:my-0">Contact</h1>
          </div>
          <div data-aos="fade-right" data-aos-duration="2000" className="md:w-1/2">
            <p className="text-xl md:text-2xl">
              Everyone we meet becomes a chapter in our story, just as we do in theirs. Join our journey, and let us collaborate to turn your next idea into reality.
            </p>
          </div>
        </div>
      </div>
      <div className="relative z-10 flex flex-col items-center justify-center h-full text-white px-4 bg-white">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center w-full max-w-7xl mb-4 md:mb-10 mt-4 md:mt-0">
          <div data-aos="fade-right" data-aos-duration="2000" className="flex flex-col items-start space-y-4 md:items-start md:w-full">
            <p className="text-base md:text-xl text-black">To request a quote or connect with us, contact us directly or fill out the form, and we'll respond promptly.</p>
            <a href="mailto:contact@xakimetal.com" target={"_blank"} className='flex items-center space-x-2 text-black'>
              <FaEnvelope className="text-lg md:text-xl text-black hover:text-nav" />
              <span className="text-base md:text-lg text-black hover:text-nav">contact@xakimetal.com</span>
            </a>
            <a href="tel:0038971984771" className="flex items-center space-x-2">
              <FaPhone className="text-lg md:text-xl text-black hover:text-nav" />
              <span className="text-base md:text-lg text-black hover:text-nav">+38971984771</span>
            </a>
            <a href="https://maps.app.goo.gl/tehbhtfY2KMh5hCfA" target={"_blank"} className="flex items-center space-x-2">
              <FaMapMarkerAlt className="text-lg md:text-xl text-black hover:text-nav" />
              <span className="text-base md:text-lg text-black hover:text-nav">Gjoce Stojcheski, Tetovo 1220, North Macedonia</span>
            </a>
            <a href="https://www.linkedin.com/company/xaki-metal/" target={"_blank"} className="flex items-center space-x-2">
              <FaLinkedin className="text-lg md:text-xl text-black hover:text-nav" />
              <span className="text-base md:text-lg text-black hover:text-nav">LinkedIn</span>
            </a>
          </div>
          <form className="space-y-4 border-2 border-black p-4 rounded-xl md:mt-10" onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Name"
              className="w-full px-4 py-2 border border-black rounded-md text-black bg-white"
              required
              value={formData.name}
              onChange={handleChange}
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="w-full px-4 py-2 border border-black rounded-md text-black bg-white"
              required
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              value={formData.email}
              onChange={handleChange}
            />
            <input
              type="tel"
              name="phone"
              placeholder="Phone Number"
              className="w-full px-4 py-2 border border-black rounded-md text-black bg-white"
              required
              pattern="[0-9+\-\(\)\s]*"
              value={formData.phone}
              onChange={handleChange}
            />
            <textarea
              name="message"
              placeholder="Message"
              className="w-full px-4 py-2 border border-black rounded-md h-32 text-black bg-white"
              required
              value={formData.message}
              onChange={handleChange}
            ></textarea>
            <button type="submit" className="px-4 py-2 bg-black hover:bg-red-700 text-white hover:text-white rounded-md w-full">
              Send Message
            </button>
            <MessageModal isOpen={isModalOpen} message={modalMessage} onClose={closeModal} />

          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUS;
